import { Theme } from '@emotion/react'
import styled from '@emotion/styled'

import React from 'react'
import { withTrans } from '../../../i18n/withTrans'
import { mq } from '../../../utility'
/*
TODO

import './../../../themes/siemens/global.css'
 */
export interface PageContainerOuterProps {
  theme?: Theme
}

export const PageContainerOuter: React.FC<PageContainerOuterProps> = ({
  children,
}) => {
  return <PCO>{children}</PCO>
}
export default withTrans(PageContainerOuter)

const PCO = styled.div(({ theme: { grid } }) => ({
  padding: '78px 40px',
  display: 'flex',
  justifyContent: 'center',
  [mq[2]]: { padding: '78px 100px' },
  [mq[3]]: { padding: '78px 140px' },
}))
