import styled from '@emotion/styled'
import React from 'react'

type InputProps = Omit<JSX.IntrinsicElements['input'], 'ref'>
export interface RadioButtonProps extends InputProps {
  ref?: React.Ref<HTMLInputElement>
}

export const Radiobutton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ children, ...rest }, ref) => (
    <StyledLabel>
      <StyledRadioButton {...rest} type="radio" ref={ref} />
      <Circle />
      <span> {children}</span>
    </StyledLabel>
  )
)
/* 'input:checked ~ div': {
  backgroundColor: colors.secondary,
  border: `2px solid ${colors.primary}`,
  color: colors.primary,
}, */
const StyledLabel = styled.label(
  ({ theme: { colors, fonts } }) => `
  cursor:pointer;
  display:flex;
  align-items:center;
  font-size:14px;
  span{
    margin-left:5px;
  }
  &:hover{
    div{
      border-color:${colors.inputBorderHover};
      &:after{
        background:${colors.inputBorderHover};
      }
    }
  }
  input:checked ~ div:after{
   display:block;
  }
`
)

const StyledRadioButton = styled.input({
  display: 'none',
})

const Circle = styled.div(
  ({ theme: { fonts, colors } }) => `
  border-radius:50%;
  box-sizing:border-box;
  width: 24px;
  height: 24px;
  border:2px solid ${colors.inputBorder};
  display:flex;
  justify-content:center;
  align-items:center;
  &:after{
    content:"";
    border-radius:50%;
    width:10px;
    height:10px;
    background: ${colors.inputBorder};
    display:none;
  }
`
)
