import styled from '@emotion/styled'
import { LocalStorageUtil } from 'cuenect-web-core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CRISP_WEBSITE_ID } from '../../../config'
import { mq } from '../../../utility'
import { replaceLinkText, TTextAlign } from '../../../utility/styleProps'
import { PageContainerOuter } from '../../organisms'
import { Button, Text } from './../../atoms'
interface ICookieConsentProps {
  page: string
  lang: string
}

export const CookieConsent = (params: ICookieConsentProps) => {
  // Bypass Consent
  const prefs = 'all' //LocalStorageUtil.getString('cookieConsent')
  const { t } = useTranslation('cookiebanner')
  const [show, setShow] = useState(
    prefs !== 'required' && prefs !== 'all' && params.page !== 'cookies'
  )

  // useEffect(() => checkConsent(), [])
  useEffect(() => checkConsent(), [])

  useEffect(() => {
    if (window.location.hash === '#showCookies') {
      setShow(true)
    }
  }, [typeof window !== 'undefined' ? window.location.hash : null])
  const setConsent = (type: string) => {
    LocalStorageUtil.saveString('cookieConsent', type)
    setShow(false)
    // checkConsent()
    checkConsent()
    if (window.location.hash === '#showCookies') {
      window.location.hash = ''
      window.location.reload()
    }
  }

  const checkConsent = () => {
    if (prefs) {
      try {
        window._paq.push(['setConsentGiven'])
      } catch (error) {}
    }

    if (prefs === 'all' || prefs === 'required') {
      window.CRISP_RUNTIME_CONFIG = {
        locale: params.lang,
      }

      if (!window.$crisp) {
        initCrisp()
      }
      if (params.page === 'support') {
        window.$crisp.push(['do', 'session:reset', [false]])
      }
    }
  }

  const initCrisp = () => {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID
    ;(function () {
      const d = document
      const s = d.createElement('script')

      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
    })()
  }

  return (
    <>
      {show && (
        <Container>
          <Dim />
          <Content>
            <PageContainerOuter>
              <PageContainerBlock>
                <PageContainerCol align="left">
                  <Text size={1} black spacing={{ bottom: 1 }}>
                    {t('headline')}
                  </Text>
                  <Text>{t('text')}</Text>
                  <Text spacing={{ top: 2 }}>
                    {replaceLinkText(
                      t('linktext'),
                      '{link}',
                      t('link'),
                      `/${params.lang}/cookies`,
                      '_blank'
                    )}
                  </Text>
                </PageContainerCol>
                <PageContainerCol align="right">
                  <ButtonBlock>
                    <ButtonRow>
                      <Button width="100%" onClick={() => setConsent('all')}>
                        {t('ctabutton2')}
                      </Button>
                    </ButtonRow>
                    <ButtonRow>
                      <Button secondary onClick={() => setConsent('required')}>
                        {t('ctabutton1')}
                      </Button>
                    </ButtonRow>
                  </ButtonBlock>
                </PageContainerCol>
              </PageContainerBlock>
            </PageContainerOuter>
          </Content>
        </Container>
      )}
    </>
  )
}
const Container = styled.div(({ theme: { colors, fonts } }) => ({
  zIndex: 999,
  position: 'fixed',
  left: 0,
  top: 0,
  height: '100vh',
  width: '100vw',
}))

const Dim = styled.div(({ theme: { colors, fonts } }) => ({
  left: 0,
  top: 0,
  height: '100vh',
  width: '100vw',
  background: colors.dim,
  opacity: 0.85,
}))

const Content = styled.div(({ theme: { colors, dimensions } }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',

  h2: { marginBottom: '2rem' },
  '& > div': {
    padding: '.5rem 0',
    [mq[3]]: { padding: '1.875rem 0' },

    background: colors.bgColor,
    width: '100vw',
  },
}))

const PageContainerBlock = styled.div(() => ({
  gridColumn: '2/36',
  [mq[0]]: {
    gridColumn: '3/35',
  },
  [mq[1]]: {
    gridColumn: '3/35',
  },
}))

interface IPageContainerCol {
  align?: TTextAlign
}

const PageContainerCol = styled.div<IPageContainerCol>(({ align }) => ({
  display: 'inline-block',
  textAlign: align,

  width: '100%',

  [mq[3]]: {
    width: '50%',
  },
}))

const ButtonBlock = styled.div(() => ({
  display: 'inherit',
  [mq[3]]: {
    marginBottom: '2rem',
  },
}))

const ButtonRow = styled.div(() => ({
  margin: '.25rem 0',

  [mq[3]]: {
    margin: '1rem 0',
  },
}))

const ButtonHolder = styled.div(({ theme: { colors, dimensions } }) => ({
  [mq[1]]: {
    display: 'flex',

    justifyContent: 'flex-end',
  },
  alignItems: 'center',
  '.button': {
    marginLeft: '3rem',
  },
}))

/*"headline": "Wir respektieren Ihre Privatsphäre",
    "copy": "Wir (Siemens AG) und bestimmte Dritte verwenden Cookies auf „siemens.com“.
    Einzelheiten zu den Arten von Cookies, ihrem Zweck und den beteiligten Stellen finden
    Sie unten und in unserem Cookie Hinweis. Bitte willigen Sie in die Verwendung von
    Cookies ein, wie in unserem Cookie Hinweis beschrieben, indem Sie auf „Alle zulassen
    und fortsetzen“ klicken, um die bestmögliche Nutzererfahrung auf unseren Webseiten zu haben.
    Sie können auch Ihre bevorzugten Einstellungen vornehmen oder Cookies ablehnen
    (mit Ausnahme unbedingt erforderlicher Cookies).",
    "link":"Cookie Hinweis und weitere Informationen",
    "buttonFull":"Fortfahren mit bestem Nutzererlebnis",
    "buttonRequired":"Nur erforderliche Cookies" */
