import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'

interface RadiobuttonGroupProps {
  label?: string
  error?: string
}

export const RadiobuttonGroup: React.FC<RadiobuttonGroupProps> = ({
  children,
  label,
  error,
}) => {
  return (
    <RadiobuttonGroupContainer className={error && 'error'}>
      <div>{children}</div>
      {error && <Error>{error}</Error>}
    </RadiobuttonGroupContainer>
  )
}

export const RadiobuttonGroupContainer = styled.div(
  ({ theme: { colors, fonts } }) => `
  font-family: ${fonts.fontFamily};
  border:none;
  outline:none;
  font-size:14px;
  display:flex;
  color:${colors.inputText};
  box-sizing: border-box;
 position:relative;
  & > div{
    display:flex;
    align-items:center;
    height:3rem;

  gap:2rem;
  }
  & > span{
    position:absolute;
    font-size:12px;
    font-family: ${fonts.fontFamily};
    color:${colors.inputBorder};
    left:16px;
    top:2px;
  }
  &.error{
    background:${rgba(colors.error, 0.2)};
  }
`
)
export const Error = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:absolute;
  bottom:-36px;
  left:0;
  color:${colors.error};
  font-size:12px;
  padding:3px 16px 0 16px;
`
)
