import styled from '@emotion/styled'
import { FeatureFlag, LocalStorageUtil, TimeUtil } from 'cuenect-web-core'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AgendaReserve, Button, BueClose, Text } from './../../'
import { LocalData, ProgramParticipants } from './../../../api'
import { eventConfig } from './../../../config'
import { AnalyticsTransformer, useSlideInContext } from './../../../utility'

interface AgendaReservateProps {
  id: string
  title: string
  participants?: ProgramParticipants | null
  eventSlug?: string | null
  registered?: boolean
  startUtc: dayjs.DayJs
  endUtc: dayjs.DayJs
  hideTestSession?: boolean
  selectedDate?: boolean
  isAppointment?: boolean
  meetingUrl?: string
}

export const AgendaReservate: React.FC<AgendaReservateProps> = ({
  id,
  title,
  eventSlug,
  participants,
  registered = false,
  startUtc,
  endUtc,
  hideTestSession = false,
  selectedDate = 0,
  isAppointment = false,
  meetingUrl = '',
}) => {
  const { t } = useTranslation('program')
  const { dispatch } = useSlideInContext()
  const [active, setActive] = React.useState(false)
  const [cancable, setCancable] = React.useState(false)
  const { capacityRemaining, signUp } = participants || {
    capacityRemaining: null,
    signUp: false,
  }
  const openReservationSlide = ({ remove = false }) => {
    dispatch({
      type: 'OPEN_WITH_COMPONENT',
      state: {
        component: AgendaReserve,
        params: {
          id,
          title,
          remove,
          selectedDate,
        },
      },
    })
    AnalyticsTransformer.customEvent(['slidein', `reservation`])
  }

  const checkAccess = () => {
    if (startUtc) {
      setActive(
        TimeUtil.getDuration(startUtc.diff(TimeUtil.getNowUtc())).asMinutes() <
          eventConfig.conversationAvailable
      )
      setCancable(
        TimeUtil.getDuration(startUtc.diff(TimeUtil.getNowUtc())).asMinutes() <
          eventConfig.conversationCancable
      )
    } else {
      setActive(false)
      setCancable(false)
    }
  }

  React.useEffect(() => {
    const inter = setInterval(checkAccess, 10000)
    checkAccess()

    return () => {
      clearInterval(inter)
    }
  }, [])

  const joinMeeting = url => {
    const userData = LocalStorageUtil.getObject<LocalData | null>('user')
      ?.person
    window.open(url)
  }

  return (
    <>
      {registered && endUtc.add(15, 'minutes').isAfter(TimeUtil.getNowUtc()) ? (
        <>
          <Button disabled={!active} onClick={() => joinMeeting(meetingUrl)}>
            {' '}
            {t('participations.join')}
          </Button>
          <FeatureFlag name="phase2">
            {!hideTestSession && (
              <Button
                secondary
                onClick={() => {
                  joinMeeting(eventConfig.testSession)
                }}
              >
                {t('participations.test')}
              </Button>
            )}
          </FeatureFlag>
          {eventSlug !== 'regional-meetup' && !cancable && !isAppointment && (
            <Button
              tertiary
              icon={BueClose}
              iconAlign="right"
              onClick={() => openReservationSlide({ remove: true })}
            >
              {t('participations.cancelSpot')}
            </Button>
          )}
        </>
      ) : (
        endUtc.isAfter(TimeUtil.getNowUtc()) && (
          <>
            <Button
              spacing={{ top: 2 }}
              disabled={!capacityRemaining}
              onClick={() => openReservationSlide({})}
            >
              {t('participations.reserve')}
            </Button>

            <InlineText>
              <Text spacing={{ top: 1 }}>
                {capacityRemaining
                  ? capacityRemaining < eventConfig.participationWarning
                    ? t('participations.fewAvailable')
                    : t('participations.available')
                  : t('participations.notAvailable')}
              </Text>
            </InlineText>
          </>
        )
      )}
    </>
  )
}
const InlineText = styled.div({
  marginTop: '2rem',
})
