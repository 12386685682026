import styled from '@emotion/styled'
import React, { forwardRef, useState } from 'react'

import { Button, BueClose } from './..'

type InputProps = Omit<JSX.IntrinsicElements['input'], 'ref'>
export interface ITextToggle extends InputProps {
  ref?: React.Ref<HTMLInputElement>
  checked?: boolean
  textOn?: string
  textOff?: string
  onChecked?: Function
}

export const TextToggle = forwardRef<HTMLInputElement, ITextToggle>(
  ({ textOn, textOff, onChecked = () => null, checked, ...rest }, ref) => {
    const [isChecked, setIsChecked] = useState(checked)

    return (
      <StyledLabel>
        <StyledCheckbox
          {...rest}
          ref={ref}
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!isChecked)
            onChecked(!isChecked)
          }}
        />
        <Button plain colored small icon={BueClose} active>
          {isChecked ? textOff : textOn}
        </Button>
      </StyledLabel>
    )
  }
)

const StyledCheckbox = styled.input({ display: 'none' })

const StyledLabel = styled.label(({ theme: { colors } }) => ({
  cursor: 'pointer',
  display: 'inline-flex',
  height: '28px',
  alignItems: 'center',
  position: 'relative',
  userSelect: 'none',
  button: {
    pointerEvents: 'none',
    svg: {
      transform: 'rotate(45deg) translateY(3px)',
    },
  },
  '& input:checked ~ button': {
    svg: {
      transform: 'rotate(0deg) translateY(0px)',
    },
  },
}))
