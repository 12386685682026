import React from 'react'
import { ServiceContainer } from 'react-service-container'
import {
  AuthenticationContextProvider,
  SlideInContextProvider,
  BookmarksContextProvider,
  ReservationContextProvider,
  ModalContextProvider,
  VodContextProvider,
  FullscreenContextProvider,
  TimezoneContextProvider,
  GlobalContextProvider,
} from './src/utility'
import { featureFlags } from './src/config'
import { FeatureFlagProvider } from 'cuenect-web-core'

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    <FeatureFlagProvider featureFlags={featureFlags}>
      <FullscreenContextProvider>
        <TimezoneContextProvider>
          <SlideInContextProvider>
            <ModalContextProvider>
              <VodContextProvider>
                <BookmarksContextProvider>
                  <ReservationContextProvider>
                    {' '}
                    {element}{' '}
                  </ReservationContextProvider>{' '}
                </BookmarksContextProvider>{' '}
              </VodContextProvider>{' '}
            </ModalContextProvider>{' '}
          </SlideInContextProvider>{' '}
        </TimezoneContextProvider>{' '}
      </FullscreenContextProvider>{' '}
    </FeatureFlagProvider>{' '}
  </GlobalContextProvider>
)
