import styled from '@emotion/styled'

import React from 'react'
import { mq } from '../../../utility'
/*
TODO
import './../../../themes/siemens/global.css'
 */
export interface PageContainerProps {
  padding?: boolean | undefined
}

interface PCProps extends Pick<PageContainerProps, 'padding'> {}

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  padding,
}) => {
  return <PC padding={padding}>{children}</PC>
}

const PC = styled.div<PCProps>(({ padding }) => ({
  maxWidth: '1300px',
  flex: 1,
}))
