import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'
import SelectSearch, { fuzzySearch, useSelect } from 'react-select-search'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Button, Input } from './../../atoms'
/* interface SelectContainerProps {
  placeholder?: string | undefined
  focus?: boolean
} */

interface SelectProps extends HTMLElement {
  error: string | undefined
  label?: string
  search?: boolean
}

interface SelectContainerProps {
  placeholder?: string | undefined
  label?: string | undefined
  focus?: boolean
  error?: boolean
  search?: boolean
  tags?: boolean
}

const CustomSelect = op => {
  const { options, value, multiple, disabled, placeholder } = op
  const [snapshot, valueProps, optionProps] = useSelect({
    options,
    value,
    multiple,
    disabled,
    search: true,
    filterOptions: fuzzySearch,
  })

  React.useEffect(() => {
    const val = !snapshot.focus ? snapshot.displayValue : snapshot.search
    val &&
      op.onChange &&
      op.onChange(!snapshot.focus ? snapshot.displayValue : snapshot.search)
  }, [snapshot.focus])

  return (
    <div className="Scroller">
      <Input
        autoComplete="new-password"
        autoFill="off"
        className={op.error && op.error !== '' ? 'error' : ''}
        {...valueProps}
        placeholder={placeholder}
        value={
          !snapshot.focus || !op.search
            ? snapshot.displayValue
            : snapshot.search
        }
      />

      {snapshot.focus && (
        <SelectScrollbar>
          <ul>
            {snapshot.options.map(option => (
              <li
                key={option.value}
                style={{
                  opacity: snapshot.value?.includes(option.value) ? '.5' : '1',
                }}
              >
                <SelectSearchOption {...optionProps} value={option.value}>
                  <span>{option.name}</span>
                </SelectSearchOption>
              </li>
            ))}
          </ul>
        </SelectScrollbar>
      )}
    </div>
  )
}

export const Select = React.forwardRef<
  SelectProps,
  JSX.IntrinsicElements['select']
>(({ ...rest }, ref) => {
  const [placeholder, setPlaceholder] = React.useState<string | undefined>(
    rest.placeholder
  )
  const [cValue, setCValue] = React.useState('')
  const [focus, setFocus] = React.useState<boolean>(false)
  const [searchvalue, setSearchvalue] = React.useState('')
  if (rest.search || rest.tags) {
    return (
      <SelectSearchContainer>
        <input type="hidden" name={rest.name} ref={ref} value={searchvalue} />
        <CustomSelect
          options={rest.options}
          {...rest}
          placeholder={rest.placeholder}
          filterOptions={fuzzySearch}
          onChange={value => {
            setSearchvalue(
              rest.options?.find(op => op.name === value)?.value || ''
            )

            setTimeout(() => {
              rest.trigger && rest.trigger()
            }, 100) // Buggy Workaround
          }}
          closeOnSelect={false}
          autoComplete="new-password"
          autoFill="off"
          printOptions="always"
          emptyMessage={'No country found'}
        />
        {rest.error && <Error>{rest.error}</Error>}
      </SelectSearchContainer>
    )
  }

  return (
    <SelectContainer
      placeholder={!cValue ? rest.placeholder : ''}
      focus={focus}
      error={rest.error}
    >
      <SelectElement
        ref={ref}
        {...rest}
        className={rest.error && rest.error !== '' ? 'error' : ''}
        onFocus={() => {
          setFocus(true)
        }}
        onBlur={e => {
          setFocus(false)
        }}
        onChange={e => {
          setCValue(e.currentTarget.value)
          rest.onChange && rest.onChange(e)
        }}
      />
      {rest.error && <Error>{rest.error}</Error>}
    </SelectContainer>
  )
})
export const SelectSearchContainer = styled.div(
  ({ theme: { colors, fonts }, label, placeholder }) => `
  position:relative;
 



  &:before{
    position:absolute;
    right:12px;
    top:22px;
    content:"";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: ${colors.primary} transparent transparent transparent;
    z-index:1;
    pointer-events:none;
  }
  .select-search__select{
    position:absolute;
    width:calc(100% - 7px);
    z-index:20;
    border:1px solid ${colors.inputBorder};
    background:#001035;
    max-height:35vh;
    overflow-y:auto;
    overflow-x:hidden;
    ul{
      list-style:none;
      margin:0;
      padding: 0;
     
      button{
       

      }
    }


  }`
)

export const SelectScrollbar = styled(PerfectScrollbar)(
  ({ theme: { colors, fonts } }) => `
  position:absolute;
    width:calc(100% - 7px);
    z-index:20;
    border:1px solid ${colors.inputBorderHover};
    background:#fff;
    height:35vh;
    overflow-y:auto;
    overflow-x:hidden;
    ul{
      list-style:none;
      margin:0;
      padding: 0;
      button{

      }
    }`
)

export const SelectSearchOption = styled.button(
  ({ theme: { colors, fonts } }) => `
  padding: 5px 10px;
  outline:none;
  border:none;
  background:none;
  display:block;
  width:100%;
  font-size: 16px;
  text-align:left;
  margin-left:0 !important;
  color: ${colors.text};
  font-family: ${fonts.fontFamily};
  cursor:pointer;
  &:hover{
    background: ${colors.inputBorder};
    color:${colors.textDark}
  }
  `
)

export const SelectContainer = styled.div<SelectContainerProps>(
  ({ theme: { colors, fonts }, label, placeholder, focus, error }) => `
  position:relative;
  &:after{    
    content: "${placeholder || ''}";
    position:absolute;
    font-family: ${fonts.fontFamily};
    left:16px;
    top:14px;
    color:${colors.inputText};
    opacity:.6;
    pointer-events:none;
    font-size:18px;

  }

  &:before{
    position:absolute;
    right:12px;
    top:22px;
    content:"";width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: ${colors.primary} transparent transparent transparent;
    pointer-events:none;

  }

`
)

export const SelectElement = styled.select(
  ({ theme: { colors, fonts } }) => `
  -webkit-appearance: none;
  width:100%;
  font-family: ${fonts.fontFamily};
  padding:0 16px;
  border:none;
  outline:none;
  font-size:18px;

  border:1px solid ${colors.inputBorder};
  color:${colors.inputText};
  box-sizing: border-box;
  height:3rem;
  &:disabled{
    border-bottom:none;
    color:${rgba(colors.inputText, 0.5)};
  }
  &:enabled:hover, &:enabled:focus{
    border:1px solid ${colors.inputBorderHover};

  }
  &:focus::placeholder{
    opacity:0;
  }
  

  &.error{
    background:${rgba(colors.error, 0.2)};
  }

  `
)
export const Error = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:absolute;
  color:${colors.error};
  font-size:12px;
  padding:3px 16px 0 16px;
`
)
