import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'
import { IconType } from 'react-icons'
import { getSpacingString, ISpacing } from '../../../utility/styleProps'

declare type BtnProps = JSX.IntrinsicElements['button']
export interface ButtonProps extends BtnProps {
  icon?: IconType
  spacing?: ISpacing
  iconAlign?: 'left' | 'right'
  hero?: boolean
  secondary?: boolean
  tertiary?: boolean
  plain?: boolean
  small?: boolean
  tag?: boolean
  asIcon?: boolean
  width?: string
  active?: boolean
  colored?: boolean
  menuItem?: boolean
  noPadding?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  icon: Icon,
  iconAlign = 'left',
  children,
  type = 'button',
  ...rest
}) => {
  return (
    <ButtonContainer type={type} {...rest}>
      {Icon && iconAlign === 'left' && <Icon className="icon icon-left" />}
      {children}
      {Icon && iconAlign === 'right' && <Icon className="icon icon-right" />}
    </ButtonContainer>
  )
}

export const ButtonContainer = styled.button<ButtonProps>(
  ({
    theme: { colors, fonts },

    asIcon,

    spacing,
  }) => {
    let btnStyles = {
      color: colors.buttonText,
      fontFamily: fonts.fontFamilyBlack,
      fontWeight: 400,
      background: colors.buttonBackground,
      marginTop: getSpacingString(spacing?.top),
      marginRight: getSpacingString(spacing?.right),
      marginBottom: getSpacingString(spacing?.bottom),
      marginLeft: getSpacingString(spacing?.left),
      cursor: 'pointer',
      outline: 'none',
      border: 'none',
      padding: '1rem',
      fontSize: fonts.fontSize,
      display: 'flex',

      // display: 'flex',
      alignItems: 'center',
      '&:hover': {
        boxShadow: '0 2px 4px 0 rgb(0 0 0 / 32%)',
      },
      '&:active': {
        background: colors.buttonBackground,
      },
      '&:disabled': {
        pointerEvents: 'none',
        color: colors.buttonTextDisabled,
        background: colors.buttonBackgroundDisabled,
      },
      svg: { fill: colors.link, width: '24px', height: '24px' },
    }
    if (asIcon) {
      return {
        ...btnStyles,
        background: 'transparent',
        padding: 0,
        '&:hover': {
          background: 'transparent',
          svg: { fill: colors.buttonSecondaryBackground },
        },
        '&:active': {
          background: 'transparent',
          svg: { fill: colors.buttonSecondaryBackground },
        },
      }
    }

    return btnStyles
  }
)
