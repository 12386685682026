const hostname = typeof window !== 'undefined' ? window.location.hostname : ''

const apiUri =
  hostname !== 'localhost'
    ? '/api/v1'
    : 'https://showroom-2021.brose.cuenect.online/api/v1'

export const apiConfig = {
  baseUrl:
    process.env.NODE_ENV === 'development'
      ? 'https://showroom-2021.brose.cuenect.online/api/v1'
      : '/api/v1',
  chatUrl: 'https://chat.cuenect.de',
  checkIn: `${apiUri}/users/check-in`,
  me: `${apiUri}/persons/me`,
  /*  appointmentSlots: `${apiUri}/appointments/slots`, */
  appointmentSlots: `/mockData/appointmentSlots.json`,
  eventParticipation: `${apiUri}/events/participation`,
  appointments: `${apiUri}/inquiry`,
  verification: `${apiUri}/users/confirm`,
  registerEvent: `${apiUri}/events`,

  register: `${apiUri}/brose-showroom-2021/inquiry`,
  sales: `${apiUri}/sales`,
  topics: `/${
    hostname === 'localhost' ? 'mockData' : '_config'
  }/topics-tree.json`,
  dialogAppointment: `${apiUri}/appointments/feedback`,
}
export const CRISP_WEBSITE_ID = 'cea0750e-0ead-4068-ab3d-d21a5b55d2b2'
export const AUTH_ACTIVE = true
