import styled from '@emotion/styled'
import { useBackdropClickListener } from 'cuenect-web-core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { Button, BueClose } from './../'
import { useDimensions } from './../../../utility'
import { mq } from './../../../utility/breakpoint'

type DivProps = React.PropsWithoutRef<JSX.IntrinsicElements['div']>

export interface ModalProps extends DivProps {
  show?: boolean
  onClickOutside?(): void
  onClose?(): void
  Icon?: IconType
}

export const Modal: React.FC<ModalProps> = ({
  children,
  onClickOutside,
  onClose,
  Icon = BueClose,
  show,
  ...rest
}) => {
  const { t } = useTranslation('core')

  return (
    <ModalBackground
      {...rest}
      show={show}
      ref={useBackdropClickListener({ callback: onClickOutside })}
    >
      <ModalBox>{children}</ModalBox>
    </ModalBackground>
  )
}

const CloseButtonContainer = styled.div(({ theme: { colors } }) => ({
  position: 'absolute',
  right: '20px',
  top: '20px',
}))

const ModalBackground = styled.div<ModalProps>(props => ({
  position: 'fixed',
  left: 0,
  top: 0,
  display: props.show ? 'flex' : 'none',
  zIndex: 1003,
  height: '100vh',
  width: '100vw',
  boxSizing: 'border-box',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0,0,0,0.8)',
  padding: '3rem',

  [mq[3]]: {
    padding: '3rem',
  },
}))

const ModalBox = styled.div(({ theme: { colors } }) => ({
  backgroundColor: colors.bodyBackground,
  padding: '5px',
  minWidth: '100px',
  minHeight: '100px',
  [mq[3]]: {
    padding: '20px 20px 20px 20px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // overflow: 'auto',
  wordWrap: 'break-word',
  flexWrap: 'wrap',
  position: 'relative',
  zindex: 100,
}))
