import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React from 'react'

interface InputProps extends HTMLInputElement {
  error: string | undefined
  label?: string | undefined
}

interface InputContainerProps {
  placeholder?: string | undefined
  label?: string | undefined
  focus?: boolean
  error?: boolean
}

export const Input = React.forwardRef<
  InputProps,
  JSX.IntrinsicElements['input']
>(({ ...rest }, ref) => {
  const [placeholder, setPlaceholder] = React.useState<string | undefined>(
    rest.label
      ? rest.label
      : rest.value !== '' && rest.value !== undefined
      ? rest.placeholder
      : ''
  )
  const [focus, setFocus] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (rest.value) {
      setPlaceholder(rest.label || rest.placeholder)
    }
  }, [rest.value])

  return (
    <InputContainer focus={focus} error={rest.error && rest.error !== ''}>
      <InputElement
        className={rest.error && rest.error !== '' ? 'error' : ''}
        ref={ref}
        {...rest}
      />
      {rest.error && <Error>{rest.error}</Error>}
    </InputContainer>
  )
})

export const InputContainer = styled.div<InputContainerProps>(
  ({ theme: { colors, fonts }, label, placeholder, focus, error }) => `
  position:relative;
  
`
)

export const InputElement = styled.input(
  ({ theme: { colors, fonts } }) => `
  font-family: ${fonts.fontFamily};
  padding:10px 16px;
  border:none;
  outline:none;
  font-size:18px;

  border:1px solid ${colors.inputBorder};
  color:${colors.inputText};
  box-sizing: border-box;
  &:disabled{
    border-bottom:none;
    color:${rgba(colors.inputText, 0.5)};
  }
  &:enabled:hover, &:enabled:focus{
    border:1px solid ${colors.inputBorderHover};

  }
  &:focus::placeholder{
    opacity:0;
  }

  &.error{
    background:${rgba(colors.error, 0.2)};
  }
  `
)
export const Error = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:absolute;
  color:${colors.error};
  font-size:12px;
  padding:3px 16px 0 16px;
`
)
