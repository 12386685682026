import { AxiosResponse } from 'axios'
import { TimeUtil } from 'cuenect-web-core'
import { SalesFormData } from '../components'
import { HttpService } from './../api/http'
import { apiConfig } from './../config'
export interface SalesUser {
  email: string
  firstName: string
  lastName: string
  companyName: string
  companyCountry: string
}
export interface SalesResource {
  email: string
  start: string
  end: string
  title: string
}
interface SalesPost {
  version: string
  description?: string
  start: string
  end: string
  host: SalesUser
  participants: SalesUser[]
  tags: string[]
}

export class SalesService {
  public static parseFormRequest(data: SalesFormData) {
    const {
      start,
      end,
      hostEmail,
      hostFirstname,
      hostLastname,
      operatingCompany,
      country,
      meetinglanguage,
      emaillanguage,
      note,
      timezone,
      event,
    } = data

    return {
      start,
      end,
      host: {
        email: hostEmail,
        firstName: hostFirstname,
        lastName: hostLastname,
        language: emaillanguage,
        timeZone: timezone,
      },
      description: note,
      participants: SalesService.getParticipants(data),
      resources: SalesService.getRecources(data),
      tags: [
        `Source: Salesform`,
        `#${emaillanguage.toUpperCase()}`,
        `Country: ${country}`,
      ],
    }
  }

  public static getParticipants = ({
    email,
    gender,
    firstName,
    lastName,
    emaillanguage,
    role,
    companyName,
    companyCountry,
  }: SalesFormData) => {
    const participants = []
    email.forEach((value, index) => {
      if (value) {
        participants.push({
          email: value,
          address: gender[index],
          firstName: firstName[index],
          lastName: lastName[index],
          language: emaillanguage,
          fields: {
            company: companyName[index],
            country: companyCountry[index],
            jobrole: role[index],
          },
        })
      }
    })

    return participants
  }

  public static getRecources = ({
    expertEmail,
    expertFirstname,
    expertLastname,
    expertStart,
    expertEnd,
    emaillanguage,
    start,
    end,
    timezone,
  }: SalesFormData) => {
    const resources = []
    expertEmail?.forEach((value, index) => {
      const hasSameTime: boolean =
        TimeUtil.getUtc(start).isSame(TimeUtil.getUtc(expertStart[index])) &&
        TimeUtil.getUtc(end).isSame(TimeUtil.getUtc(expertEnd[index]))

      if (value) {
        resources.push({
          email: value,
          firstName: expertFirstname[index],
          lastName: expertLastname[index],
          start: hasSameTime ? null : expertStart[index],
          end: hasSameTime ? null : expertEnd[index],
          timeZone: timezone,
          language: emaillanguage,
        })
      }
    })

    return resources
  }

  public static async sendForm(data: SalesFormData) {
    console.log('parsedData OLD', data)

    const parsedData = SalesService.parseFormRequest(data)
    console.log('parsedData', parsedData)

    const result: AxiosResponse | false = await HttpService.post({
      url: apiConfig.sales,
      data: parsedData,
    })

    return result
  }
}
