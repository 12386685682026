// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-request-sent-tsx": () => import("./../../../src/templates/request-sent.tsx" /* webpackChunkName: "component---src-templates-request-sent-tsx" */),
  "component---src-templates-request-tsx": () => import("./../../../src/templates/request.tsx" /* webpackChunkName: "component---src-templates-request-tsx" */),
  "component---src-templates-salesform-7-hs-5-hz-3-as-tsx": () => import("./../../../src/templates/salesform-7hs5Hz3as.tsx" /* webpackChunkName: "component---src-templates-salesform-7-hs-5-hz-3-as-tsx" */),
  "component---src-templates-support-tsx": () => import("./../../../src/templates/support.tsx" /* webpackChunkName: "component---src-templates-support-tsx" */),
  "component---src-templates-verification-sent-tsx": () => import("./../../../src/templates/verification-sent.tsx" /* webpackChunkName: "component---src-templates-verification-sent-tsx" */),
  "component---src-templates-verification-tsx": () => import("./../../../src/templates/verification.tsx" /* webpackChunkName: "component---src-templates-verification-tsx" */)
}

